import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser } from './redux/slices/myselfSlice'
import Navbar from './components/Navbar'
import NavbarLoading from './components/NavbarLoading'
import Login from './pages/Login'
import Signup from './pages/Signup'
import ErrorPage from './pages/ErrorPage'
import HomePage from './pages/HomePage'
import Footer from './components/Footer'
import FarmsharePage from './pages/FarmsharePage'
import FarmsharePageInProgress from './pages/FarmsharePageInProgress'
import AchievementsPage from './pages/AchievementsPage'
import AboutUsPage from './pages/AboutUsPage'
import RCDPage from './pages/RCDPage'
import ContactUsPage from './pages/ContactUsPage'
import EventPage from './pages/EventPage'
import ResourcesPage from './pages/ResourcesPage'
import PrivacyPage from './pages/PrivacyPage'
import DetailEquipmentPage from './pages/DetailEquipmentPage'
import HomePageBG from './assets/HomepageBG.jpg'
import UserList from './pages/UserList'
import ReservationList from './pages/ReservationList'
import NewUserPage from './pages/NewUserPage'
import UserProfilePage from './pages/UserProfilePage'
import UserReservations from './pages/UserReservations'
import EquipmentListPage from './pages/EquipmentListPage'
import ProtectedRoute from './components/ProtectedRoute'

const App = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getCurrentUser())
  }, [dispatch])

  const newUser = useSelector((state) => state.myself.newUser)

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/events" element={<EventPage />} />
        <Route
          path="/farmshare"
          element={newUser ? <NewUserPage /> : <FarmsharePage />}
        />
        <Route
          path="/farmshare/:equipmentId"
          element={<DetailEquipmentPage />}
        />
        <Route
          path="/farmshare/profile"
          element={
            <ProtectedRoute>
              <UserProfilePage />
            </ProtectedRoute>
          }
        />
        <Route path="/farmshareIP" element={<FarmsharePageInProgress />} />
        <Route path="/achievements" element={<AchievementsPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/rcd" element={<RCDPage />} />
        <Route path="/resources" element={<ResourcesPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route
          path="/userlist"
          element={
            <ProtectedRoute requireAdmin={true}>
              <UserList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reservationlist"
          element={
            <ProtectedRoute requireAdmin={true}>
              <ReservationList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/myreservations"
          element={
            <ProtectedRoute>
              <UserReservations />
            </ProtectedRoute>
          }
        />
        <Route
          path="/equipmentlist"
          element={
            <ProtectedRoute requireAdmin={true}>
              <EquipmentListPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="*"
          element={
            <>
              <Navbar src={HomePageBG} pageName={``} />
              <ErrorPage />
            </>
          }
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}

export default App
