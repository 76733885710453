import React from 'react';

const ContentBox = ({
  bgColor,
  title,
  titleColor,
  contentColor,
  content,
  textAlign = 'text-left',
  maxContentWidth = 'max-w-6xl',
  maxContentPad = '10',
}) => {
  return (
    <div
      style={{ backgroundColor: bgColor }}
      className={`my-4 px-[8vw] md:px-16 lg:px-[10vw] py-5`}
    >
      <h1
        style={{ color: titleColor }}
        className={`underline text-2xl md:text-3xl lg:text-[35px] font-bold pt-5 md:pt-10 text-shadow-sm`}
      >
        {title}
      </h1>
      <p
        style={{ color: contentColor }}
        className={`${maxContentWidth} ${textAlign} text-md lg:text-[18px] py-5 lg:p-${maxContentPad}`}
      >
        {content}
      </p>
    </div>
  );
};

export default ContentBox;
