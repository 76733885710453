import PropTypes from 'prop-types';

const ExternalLinkButton = ({ children, ...props }) => {
  /*className="w-[150px] rounded-full font-medium my-6 mx-auto md:mx-0 "*/
  return (
    <a
      className="bg-lime-800 text-white font-bold px-6 py-3 md:text-[20px] md:mx-0 rounded-full shadow-md
            cursor-pointer outline-none border-none select-none "
      {...props}
    >
      {children}
    </a>
  );
};

export default ExternalLinkButton;

ExternalLinkButton.propTypes = {
  children: PropTypes.node.isRequired,
};
