import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { MoonLoader } from 'react-spinners'
import NavbarLoading from './NavbarLoading'
import HomePageBG from '../assets/HomepageBG.jpg'

const ProtectedRoute = ({ children, requireAdmin = false }) => {
  const user = useSelector((state) => state.myself.user)
  const loading = useSelector((state) => state.myself.fetchingUser)

  if (loading) {
    return (
      <div>
        <NavbarLoading
          src={HomePageBG}
          pageName={'Sustainable Land Initiative'}
        />
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
          <MoonLoader color="#000" size={50} />
        </div>
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/login" />
  }

  if (requireAdmin && user.role !== 'admin') {
    return <Navigate to="/" />
  }

  return children
}

export default ProtectedRoute
