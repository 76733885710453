import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AiOutlineClose, AiOutlineMenu, AiOutlineUser } from 'react-icons/ai';
import Logo from '../assets/Logo.svg';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ExternalLinkButton from './ExternalLinkButton';
import { logoutRequested } from '../redux/slices/authSlice';

const Navbar = ({ src, pageName, pageText }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.myself.isAuthenticated);
  const isLoading = useSelector(state => state.auth.isAuthenticated);
  const navigate = useNavigate();
  const [nav, setNav] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [mobileDropdown, setMobileDropdown] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const handleAccount = () => {
    setShowDropdown(!showDropdown);
  };

  const handleMobileAccount = () => {
    setMobileDropdown(!mobileDropdown);
  };

  useEffect(() => {}, [isAuthenticated]);

  return (
    <>
      <ToastContainer />
      <div className="h-[2rem] w-auto bg-[#2E7A32] relative">
        <div className="p-1 text-white "></div>
      </div>
      <div
        style={{ color: '#FFEB83' }}
        className="text-4xl lg:text-5xl xl:text-6xl font-bold text-shadow-lg max-w-[75vw] lg:max-w-[70vw] mx-[8vw] md:mx-16 lg:mx-[10vw] top-[110px] md:top-[200px] lg:top-[250px] absolute z-20"
      >
        {pageName}
        <div className="text-[17px] leading-normal md:flex w-auto h-auto mt-3 md:max-w-[75vw] md:text-xl lg:text-2xl font-semibold">
          {pageText}
        </div>
      </div>
      <div
        style={{ backgroundImage: `url(${src})` }}
        className={`flex items-start min-h-[350px] md:min-h-[400px] lg:min-h-[500px] text-[#2E7A32] text-xs sm:text-md md:text-lg lg:text-lg xl:text-xl 3xl:text-2xl bg-cover bg-no-repeat bg-center relative pl-31 pt-14`}
      >
        <Link to="/">
          <div className="mr-6 hidden xl:block">
            <img
              className="hidden xl:flex w-[100px] h-auto ml-[10vw]"
              src={Logo}
              alt="/"
            />
          </div>
        </Link>
        <div className="">
          <ul className="hidden text-[1rem] lg:text-[1.2rem] mt-5 gap-2 lg:gap-4 md:flex ml-[8vw] md:ml-16 lg:ml-[10vw] xl:ml-0">
            <Link to="/">
              <li className="px-3 py-1 lg:py-2 bg-[#A8C356] min-w-[9vw] rounded-xl flex justify-center border-b border-gray-100">
                Home
              </li>
            </Link>
            <Link to="/about-us">
              <li className="px-2 py-1 lg:py-2 bg-[#A8C356] min-w-[9vw] rounded-xl flex justify-center border-b border-gray-100">
                About&nbsp;SLI
              </li>
            </Link>
            <Link to="/farmshare">
              <li className="px-2 py-1 lg:py-2 bg-[#A8C356] min-w-[9vw] rounded-xl flex justify-center border-b border-gray-100">
                Farmshare
              </li>
            </Link>
            <Link to="/events">
              <li className="px-2 py-1 lg:py-2 bg-[#A8C356] min-w-[9vw] rounded-xl flex justify-center border-b border-gray-100">
                Events
              </li>
            </Link>
            <Link to="/resources">
              <li className="px-2 py-1 lg:py-2 bg-[#A8C356] min-w-[9vw] rounded-xl flex justify-center border-b border-gray-200">
                Resources
              </li>
            </Link>
          </ul>
        </div>

        {!isAuthenticated && (
          <div className="">
            <a
              href="https://usltrcd-prod-mobile.f2-cloud.com/SelfService/submission/submit/Intake_form"
              target="_blank"
            >
              <ul className="hidden md:flex m-5 absolute right-[10vw] text-[1rem] lg:text-[1.2rem]">
                <li className="block px-3 py-1 lg:py-2 bg-lime-800 text-white border-b font-bold rounded-full w-auto flex items-center justify-center shadow">
                  <span>Enroll&nbsp;Now!</span>
                </li>
              </ul>
            </a>
          </div>
        )}

        {isAuthenticated && (
          <div className="hidden md:block absolute right-[10vw] m-5 cursor-pointer">
            <div
              onClick={handleAccount}
              className="px-3 py-1 lg:py-2 bg-lime-800 text-white border-b font-bold rounded-full shadow select-none"
            >
              My Account
            </div>
            {showDropdown && (
              <div className="bg-lime-800 rounded-lg h-auto mt-1 w-auto text-white shadow border-b">
                <div className="flex flex-col">
                  <Link
                    to="/reservationlist"
                    className="px-4 py-2 text-sm hover:bg-lime-700 rounded-t-lg select-none"
                  >
                    Reservations
                  </Link>
                  <hr />
                  <Link
                    to="/farmshare/profile"
                    className="px-4 py-2 text-sm hover:bg-lime-700 select-none"
                  >
                    View Profile
                  </Link>
                  <hr />
                  <div
                    onClick={e => {
                      e.preventDefault();
                      dispatch(logoutRequested());
                      navigate('/');
                    }}
                    className="px-4 py-2 text-sm hover:bg-lime-700 rounded-b-lg select-none"
                  >
                    Logout
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        <div className="md:hidden absolute right-7 md:right-14 top-8 flex gap-2">
          {isAuthenticated && (
            <div
              onClick={handleMobileAccount}
              className="text-white bg-[#2E7A32] rounded-full cursor-pointer border border-green-900 shadow"
            >
              <AiOutlineUser className="p-1" size={30} />
            </div>
          )}
          <div
            onClick={handleNav}
            className="md:hidden text-white bg-[#2E7A32] text-center rounded cursor-pointer border border-green-900 shadow"
          >
            <AiOutlineMenu className="p-1" size={30} />
          </div>
        </div>
        {/* <div
          onClick={handleNav}
          className="block md:hidden rounded text-[#FFEB83] font-Merriweather-Sans absolute bg-[#2E7A32] right-7 md:right-14 top-12 cursor-pointer"
        >
          {nav ? <div /> : <AiOutlineMenu className="p-1" size={30} />}
        </div> */}
        {/* <AiOutlineClose size={20} /> */}
        <ul
          className={
            nav
              ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-[#A8C356] bg-[#A8C356] shadow-lg ease-in-out duration-500 z-20'
              : 'ease-in-out duration-500 fixed left-[-100%]'
          }
        >
          <div
            onClick={handleNav}
            className="cursor-pointer absolute right-5 top-5"
          >
            <AiOutlineClose size={20} />
          </div>

          <Link to="/">
            <h1 className="w-auto text-3xl font-bold text-[#2E7A32] m-4">
              SLI
            </h1>
          </Link>
          <Link to="/">
            <li className="p-4 border-b border-[#2E7A32]">Home</li>
          </Link>
          <Link to="/about-us">
            <li className="p-4 border-b border-[#2E7A32]">About SLI</li>
          </Link>
          <Link to="/farmshare">
            <li className="p-4 border-b border-[#2E7A32]">Farmshare</li>
          </Link>
          <Link to="/events">
            <li className="p-4 border-b border-[#2E7A32]">Events</li>
          </Link>
          <Link to="/resources">
            <li className="p-4 border-b border-[#2E7A32]">Resources</li>
          </Link>
          {/*<Link to="/signup">
            <li className="p-4">Sign Up</li>
        </Link>*/}
          <div>
            <li className="p-4">
              <a
                href="https://usltrcd-prod-mobile.f2-cloud.com/SelfService/submission/submit/Intake_form"
                target="_blank"
              >
                Enroll
              </a>
            </li>
          </div>
          {/*<Link to="/signup">
            <li className="p-4">Sign Up</li>
        </Link>*/}
        </ul>
        <ul
          className={
            mobileDropdown
              ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-[#A8C356] bg-[#A8C356] shadow-lg ease-in-out duration-500 z-20'
              : 'ease-in-out duration-500 fixed left-[-100%]'
          }
        >
          <div
            onClick={handleMobileAccount}
            className="cursor-pointer absolute right-5 top-5"
          >
            <AiOutlineClose size={20} />
          </div>

          <Link to="/">
            <h1 className="w-auto text-3xl font-bold text-[#2E7A32] m-4">
              SLI
            </h1>
          </Link>
          <Link to="/reservationlist">
            <li className="p-4 border-b border-[#2E7A32]">View Profile</li>
          </Link>
          <Link to="/farmshare-profile">
            <li className="p-4 border-b border-[#2E7A32]">Reservations</li>
          </Link>
          <div
            onClick={e => {
              e.preventDefault();
              dispatch(logoutRequested());
              navigate('/');
            }}
          >
            <li className="p-4">Logout</li>
          </div>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
